/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Icon,
  Image,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function Section1Rsp2(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "AI\u30AB\u30E1\u30E9\u304B\u3089\u53D6\u5F97\u3057\u305F\u6620\u50CF\u3001AI\u30C7\u30FC\u30BF\u3001IoT\u30C7\u30FC\u30BF\u3092\u7D71\u5408\u7BA1\u7406\u30FB\u53EF\u8996\u5316\u3067\u304D\u308B\u30AF\u30E9\u30A6\u30C9\u30B5\u30FC\u30D3\u30B9\u3067\u3059\u3002 SaaS\u63D0\u4F9B\u306E\u305F\u3081\u3001\u65B0\u305F\u306A\u30B7\u30B9\u30C6\u30E0\u69CB\u7BC9\u4E0D\u8981\u30FB\u521D\u671F\u6295\u8CC7\u4E0D\u8981\u3067\u3059\u3050\u306B\u3054\u5229\u7528\u53EF\u80FD\u3067\u3059\u3002":
          {},
        "THEASIGHT\u3068\u306F 1": {},
        "Rectangle 32233474": {},
        "\u7279\u5FB4\uFF11": {},
        "Group 90": {},
        "Rectangle 32333475": {},
        "\u6620\u50CF\u30C7\u30FC\u30BF\u3084\u30BB\u30F3\u30B5\u30FC\u30C7\u30FC\u30BF\u3001AI\u51E6\u7406\u306E\u7D50\u679C\u3092\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u4ECB\u3057\u3066\u30AF\u30E9\u30A6\u30C9\u3078\u96C6\u7D04\u3002":
          {},
        "Group 92": {},
        "\u7279\u5FB433473": {},
        "Rectangle 32233479": {},
        "\u7279\u5FB4\uFF12": {},
        "Group 91": {},
        "Rectangle 32333480": {},
        "\u30AF\u30E9\u30A6\u30C9\u4E0A\u3067\u6620\u50CF/AI/IoT\u306E\u7D71\u5408\u7BA1\u7406\u3002\u4ED6\u30B7\u30B9\u30C6\u30E0\u3068\u9023\u643A\u3057\u305F\u30C7\u30FC\u30BF\u6D3B\u7528\u57FA\u76E4\u3002":
          {},
        "Group 93": {},
        "\u7279\u5FB433478": {},
        "Frame 411": {},
        "Rectangle 283": {},
        "THEASIGHT\u3068\u306F\uFF1F": {},
        "Group 89": {},
        "Frame 412": {},
        Section1Rsp2: {},
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "AI\u30AB\u30E1\u30E9\u304B\u3089\u53D6\u5F97\u3057\u305F\u6620\u50CF\u3001AI\u30C7\u30FC\u30BF\u3001IoT\u30C7\u30FC\u30BF\u3092\u7D71\u5408\u7BA1\u7406\u30FB\u53EF\u8996\u5316\u3067\u304D\u308B\u30AF\u30E9\u30A6\u30C9\u30B5\u30FC\u30D3\u30B9\u3067\u3059\u3002 SaaS\u63D0\u4F9B\u306E\u305F\u3081\u3001\u65B0\u305F\u306A\u30B7\u30B9\u30C6\u30E0\u69CB\u7BC9\u4E0D\u8981\u30FB\u521D\u671F\u6295\u8CC7\u4E0D\u8981\u3067\u3059\u3050\u306B\u3054\u5229\u7528\u53EF\u80FD\u3067\u3059\u3002":
          {
            fontSize: "12px",
            lineHeight: "14.522727012634277px",
            width: "312px",
            height: "98px",
            top: "40px",
            left: "0px",
          },
        "THEASIGHT\u3068\u306F 1": {
          width: "261px",
          height: "180px",
          top: "338px",
          left: "26px",
        },
        "Rectangle 32233474": {
          width: "261px",
          height: "20.01px",
          top: "calc(50% - 10.01px - 0px)",
          left: "calc(50% - 130.5px - 0px)",
        },
        "\u7279\u5FB4\uFF11": {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
          width: "240.99px",
          height: "unset",
          top: "calc(50% - 8.5px - -0.33px)",
          left: "calc(50% - 120.49px - -0.83px)",
          children: "\u7279\u5FB41",
        },
        "Group 90": { width: "261px", height: "20.01px" },
        "Rectangle 32333475": {
          width: "261px",
          height: "60.04px",
          top: "calc(50% - 30.02px - 0px)",
          left: "calc(50% - 130.5px - 0px)",
        },
        "\u6620\u50CF\u30C7\u30FC\u30BF\u3084\u30BB\u30F3\u30B5\u30FC\u30C7\u30FC\u30BF\u3001AI\u51E6\u7406\u306E\u7D50\u679C\u3092\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u4ECB\u3057\u3066\u30AF\u30E9\u30A6\u30C9\u3078\u96C6\u7D04\u3002":
          {
            fontSize: "12px",
            lineHeight: "14.522727012634277px",
            width: "246.82px",
            height: "unset",
            top: "calc(50% - 15px - 2.02px)",
            left: "calc(50% - 123.41px - -0.42px)",
          },
        "Group 92": { width: "261px", height: "60.04px", top: "20.01px" },
        "\u7279\u5FB433473": { width: "261px", height: "80.05px" },
        "Rectangle 32233479": {
          width: "261px",
          height: "20.01px",
          top: "calc(50% - 10.01px - 0px)",
          left: "calc(50% - 130.5px - 0px)",
        },
        "\u7279\u5FB4\uFF12": {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
          width: "240.99px",
          height: "unset",
          top: "calc(50% - 8.5px - -0.33px)",
          left: "calc(50% - 120.49px - -0.83px)",
        },
        "Group 91": { width: "261px", height: "20.01px" },
        "Rectangle 32333480": {
          width: "261px",
          height: "60.04px",
          top: "calc(50% - 30.02px - 0px)",
          left: "calc(50% - 130.5px - 0px)",
        },
        "\u30AF\u30E9\u30A6\u30C9\u4E0A\u3067\u6620\u50CF/AI/IoT\u306E\u7D71\u5408\u7BA1\u7406\u3002\u4ED6\u30B7\u30B9\u30C6\u30E0\u3068\u9023\u643A\u3057\u305F\u30C7\u30FC\u30BF\u6D3B\u7528\u57FA\u76E4\u3002":
          {
            fontSize: "12px",
            lineHeight: "14.522727012634277px",
            width: "246.82px",
            height: "unset",
            top: "calc(50% - 15px - 2.02px)",
            left: "calc(50% - 123.41px - -0.42px)",
          },
        "Group 93": { width: "261px", height: "60.04px", top: "20.01px" },
        "\u7279\u5FB433478": { width: "261px", height: "80.05px" },
        "Frame 411": {
          gap: "18px",
          direction: "column",
          width: "unset",
          alignItems: "center",
          top: "143px",
          left: "26px",
        },
        "Rectangle 283": {
          width: "197px",
          height: "8px",
          viewBox: { minX: 0, minY: 0, width: 197, height: 8 },
          paths: [
            {
              d: "M0 0L197 0L197 8L0 8L0 0Z",
              fill: "rgba(116,171,255,1)",
              fillRule: "nonzero",
            },
          ],
          top: "14px",
        },
        "THEASIGHT\u3068\u306F\uFF1F": {
          fontSize: "20px",
          lineHeight: "24.204544067382812px",
          width: "186.52px",
          left: "10.48px",
        },
        "Group 89": { width: "197px", height: "24px", left: "57px" },
        "Frame 412": { width: "312px", height: "518px" },
        Section1Rsp2: {
          direction: "row",
          height: "unset",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "34px 22px 34px 22px",
        },
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="1401px"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="119px 382px 119px 382px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      id="section1rsp2"
      {...getOverrideProps(overrides, "Section1Rsp2")}
      {...rest}
    >
      <View
        width="1019px"
        height="408px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 412")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="30px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="1036px"
          height="136px"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="84px"
          left="-8.5px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="&#xA;AIカメラから取得した映像、AIデータ、IoTデータを統合管理・可視化できるクラウドサービスです。&#xA;SaaS提供のため、新たなシステム構築不要・初期投資不要ですぐにご利用可能です。"
          {...getOverrideProps(
            overrides,
            "AI\u30AB\u30E1\u30E9\u304B\u3089\u53D6\u5F97\u3057\u305F\u6620\u50CF\u3001AI\u30C7\u30FC\u30BF\u3001IoT\u30C7\u30FC\u30BF\u3092\u7D71\u5408\u7BA1\u7406\u30FB\u53EF\u8996\u5316\u3067\u304D\u308B\u30AF\u30E9\u30A6\u30C9\u30B5\u30FC\u30D3\u30B9\u3067\u3059\u3002 SaaS\u63D0\u4F9B\u306E\u305F\u3081\u3001\u65B0\u305F\u306A\u30B7\u30B9\u30C6\u30E0\u69CB\u7BC9\u4E0D\u8981\u30FB\u521D\u671F\u6295\u8CC7\u4E0D\u8981\u3067\u3059\u3050\u306B\u3054\u5229\u7528\u53EF\u80FD\u3067\u3059\u3002"
          )}
        ></Text>
        <Image
          width="calc(100% - -28px)"
          height="612px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="430px"
          left="-13.5px"
          right="-14.5px"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://theasight.net/website_image/THEASIGHT_atc.png"
          {...getOverrideProps(overrides, "THEASIGHT\u3068\u306F 1")}
        ></Image>
        <Flex
          gap="52px"
          direction="row"
          width="851px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          position="absolute"
          top="242px"
          left="69.5px"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 411")}
        >
          <Flex
            padding="0px 0px 0px 0px"
            width="409px"
            height="127.08px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "\u7279\u5FB433473")}
          >
            <View
              padding="0px 0px 0px 0px"
              width="409px"
              height="33.59px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="0px"
              {...getOverrideProps(overrides, "Group 90")}
            >
              <View
                width="409px"
                height="33.59px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                bottom="0px"
                left="calc(50% - 204.5px - 0px)"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(68,114,196,1)"
                {...getOverrideProps(overrides, "Rectangle 32233474")}
              ></View>
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="700"
                color="rgba(255,255,255,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="70.89px"
                height="18.99px"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="calc(50% - 9.49px - -0.49px)"
                left="calc(50% - 35.45px - -1.01px)"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="特徴 １"
                {...getOverrideProps(overrides, "\u7279\u5FB4\uFF11")}
              ></Text>
            </View>
            <View
              padding="0px 0px 0px 0px"
              width="409px"
              height="93.48px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="33.59px"
              left="0px"
              {...getOverrideProps(overrides, "Group 92")}
            >
              <View
                width="409px"
                height="93.48px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="calc(50% - 46.74px - 0px)"
                left="calc(50% - 204.5px - 0px)"
                border="1px SOLID rgba(47,82,143,1)"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Rectangle 32333475")}
              ></View>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="394.69px"
                height="45.44px"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="calc(50% - 22.72px - 0.61px)"
                left="calc(50% - 197.35px - 0.15px)"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="映像データやセンサーデータ、AI処理の結果をネットワークを介してクラウドへ集約。"
                {...getOverrideProps(
                  overrides,
                  "\u6620\u50CF\u30C7\u30FC\u30BF\u3084\u30BB\u30F3\u30B5\u30FC\u30C7\u30FC\u30BF\u3001AI\u51E6\u7406\u306E\u7D50\u679C\u3092\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u4ECB\u3057\u3066\u30AF\u30E9\u30A6\u30C9\u3078\u96C6\u7D04\u3002"
                )}
              ></Text>
            </View>
          </Flex>
          <Flex
            padding="0px 0px 0px 0px"
            width="390px"
            height="127px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "\u7279\u5FB433478")}
          >
            <View
              padding="0px 0px 0px 0px"
              width="390px"
              height="32.03px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="0px"
              {...getOverrideProps(overrides, "Group 91")}
            >
              <View
                width="390px"
                height="32.03px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                bottom="0px"
                left="0px"
                right="0px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(68,114,196,1)"
                {...getOverrideProps(overrides, "Rectangle 32233479")}
              ></View>
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="700"
                color="rgba(255,255,255,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="364.14px"
                height="18.11px"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="7.43px"
                bottom="6.5px"
                left="13.97px"
                right="11.9px"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="特徴２"
                {...getOverrideProps(overrides, "\u7279\u5FB4\uFF12")}
              ></Text>
            </View>
            <View
              padding="0px 0px 0px 0px"
              width="390px"
              height="94.97px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="32.03px"
              left="0px"
              {...getOverrideProps(overrides, "Group 93")}
            >
              <View
                width="390px"
                height="94.97px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                border="1px SOLID rgba(47,82,143,1)"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Rectangle 32333480")}
              ></View>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="370px"
                height="46px"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="calc(50% - 23px - -0.48px)"
                left="calc(50% - 185px - 2px)"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="クラウド上で映像/AI/IoTの統合管理。他システムと連携したデータ活用基盤。"
                {...getOverrideProps(
                  overrides,
                  "\u30AF\u30E9\u30A6\u30C9\u4E0A\u3067\u6620\u50CF/AI/IoT\u306E\u7D71\u5408\u7BA1\u7406\u3002\u4ED6\u30B7\u30B9\u30C6\u30E0\u3068\u9023\u643A\u3057\u305F\u30C7\u30FC\u30BF\u6D3B\u7528\u57FA\u76E4\u3002"
                )}
              ></Text>
            </View>
          </Flex>
        </Flex>
        <View
          padding="0px 0px 0px 0px"
          width="481px"
          height="62px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="260.5px"
          {...getOverrideProps(overrides, "Group 89")}
        >
          <Icon
            width="481px"
            height="19px"
            viewBox={{ minX: 0, minY: 0, width: 481, height: 19 }}
            paths={[
              {
                d: "M0 0L481 0L481 20L0 20L0 0Z",
                fill: "rgba(116,171,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="28px"
            left="0px"
            {...getOverrideProps(overrides, "Rectangle 283")}
          ></Icon>
          <Text
            fontFamily="Inter"
            fontSize="40px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="48.409088134765625px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0px"
            left="57px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="THEASIGHTとは？"
            {...getOverrideProps(overrides, "THEASIGHT\u3068\u306F\uFF1F")}
          ></Text>
        </View>
      </View>
    </Flex>
  );
}

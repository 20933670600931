import './App.css';
//import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
//import { Amplify, Auth } from 'aws-amplify';
//import {Authenticator, withAuthenticator} from '@aws-amplify/ui-react';
//import aws_exports from './aws-exports';
import { HeaderRsp } from './ui-components';
import { MainRsp } from './ui-components';
import { Section1Rsp } from './ui-components';
import { Section1Rsp2 } from './ui-components';
import { Section2Rsp } from './ui-components';
import { Section3Rsp } from './ui-components';
import { Section4Rsp } from './ui-components';
import { Section5Rsp } from './ui-components';
import { Section6Rsp } from './ui-components';
import { Fotter } from './ui-components';
//Amplify.configure(aws_exports);

function App() {
  return (
    <div>
      <HeaderRsp />
      <MainRsp />
      <Section1Rsp/>
      <Section1Rsp2 />
      <Section2Rsp />
      <Section3Rsp />
      <Section4Rsp />
      <Section5Rsp />
      <Section6Rsp />
      <Fotter />
    </div>
    //<Authenticator>
      //<div className='App'>
      //  <header className='App-header'>
      //    <h1>Sample React app</h1>
      //    <h2><a className='App-link' href="." onClick={Auth.signOut}>Sign out</a></h2>
      //  </header>
      //</div>
    //</Authenticator>
  );
}

export default App;

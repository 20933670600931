/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Image, useBreakpointValue } from "@aws-amplify/ui-react";
export default function HeaderRsp(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "\u30C6\u30A2\u30B5\u30A4\u30C8\u30ED\u30B4": {},
        "toibtnhead 1": {},
        "Frame 81": {},
        HeaderRsp: {},
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "\u30C6\u30A2\u30B5\u30A4\u30C8\u30ED\u30B4": {
          width: "170px",
          height: "16px",
        },
        "toibtnhead 1": { width: "111px", height: "22px" },
        "Frame 81": {
          gap: "86px",
          width: "390px",
          height: "35px",
          padding: "10px 8px 10px 10px",
        },
        HeaderRsp: { height: "35px", justifyContent: "center" },
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const OnClick = useNavigateAction({
    type: "url",
    url: "https://info.theasight.net",
  });
  const toibtnheadOneOnClick = useNavigateAction({
    type: "url",
    url: "https://theasight.net/send.html",
    target: "_blank",
  });
  const zIndex ={ style: { zIndex: 9999 }, ...restProp };
  const cursor = { style: { cursor: "pointer" }, ...restProp };
  return (
    <Flex
      //gap="1291px"
      direction="row"
      width="100%"
      height="74px"
      justifyContent="flex-start"
      alignItems="center"
      //position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      position="fixed"
      top= "0"
      left= "0"     
      {...getOverrideProps(overrides, "HeaderRsp")}
      {...rest}
      {...zIndex}
    > 
      <Flex
        //gap="1228px"
        direction="row"
        width="100%"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        //position="relative"
        padding="13px 38px 13px 38px"
        backgroundColor="rgba(3,3,14, 0.75)"
        display="flex"
        {...zIndex}
        {...getOverrideProps(overrides, "Frame 81")}
      >
        <Image
          width="368.89px"
          height="33px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://theasight.net/website_image/Header-icon.png"
          onClick={() => {
            OnClick();
          }}
          {...getOverrideProps(
            overrides,
            "\u30C6\u30A2\u30B5\u30A4\u30C8\u30ED\u30B4"
          )}
        ></Image>
        <Image
          width="auto"
          height="43px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://theasight.net/website_image/toibtnhead.png"
          onClick={() => {
            toibtnheadOneOnClick();
          }}
          {...cursor}
          {...getOverrideProps(overrides, "toibtnhead 1")}
        ></Image>
      </Flex>
    </Flex>
  );
}

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View, useBreakpointValue } from "@aws-amplify/ui-react";
export default function Fotter(props) {
  const { group66160107, overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        "THEASGHT UI\u753B\u9762": {},
        "Group 703575": {},
        "THEASGHT\u3067\u5B9F\u73FE\u3067\u304D\u308B\u3053\u3068": {},
        "Group 663573": {},
        "Group 693572": {},
        "THEASGHT\u3068\u306F\uFF1F": {},
        "Group 66160107": {},
        "Group 66160103": {},
        "THEASIGHT\u306B\u3064\u3044\u3066": {},
        "Group 66160100": {},
        "Group 6816096": {},
        "Group 6816091": {},
        "IoTube\u306E\u6A5F\u80FD": {},
        "Group 6616088": {},
        "Group 67": {},
        "Group 82": {},
        "\u304A\u554F\u3044\u5408\u308F\u305B": {},
        "Group 6616081": {},
        "Group 72": {},
        "\u30B5\u30DD\u30FC\u30C8": {},
        "Group 6616074": {},
        "Group 6816070": {},
        "Group 6916065": {},
        "Group 83": {},
        "Group 86155168": {},
        "\u30B5\u30FC\u30D3\u30B9\u7D04\u6B3E": {},
        "Group 66160135": {},
        "Group 73": {},
        "\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC": {},
        "Group 66160128": {},
        "Group 74": {},
        "\u7D04\u6B3E": {},
        "Group 66160121": {},
        "Group 71": {},
        "Group 84": {},
        "\u904B\u55B6\u4F1A\u793E": {},
        CTC: {},
        "Group 66160114": {},
        "Group 70160110": {},
        "Group 85": {},
        "Group 87": {},
        "\u30D1\u30FC\u30C8\u30CA\u30FC": {},
        MOYAI: {},
        "NTT\u30B3\u30DF\u30E5\u30CB\u30B1\u30FC\u30B7\u30E7\u30F3\u30BA": {},
        "Group 8655620": {},
        "Group 88": {},
        "Frame 85": {},
        "THEASIGHT\u00A92023 All Rights Reserved.": {},
        Fotter: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        "THEASGHT UI\u753B\u9762": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "124.59px",
          height: "unset",
          top: "33.33%",
          bottom: "0%",
          left: "6%",
          right: "-12%",
        },
        "Group 703575": {
          width: "117.54px",
          height: "18px",
          top: "35.85%",
          bottom: "43.89%",
          left: "0%",
          right: "36.61%",
        },
        "THEASGHT\u3067\u5B9F\u73FE\u3067\u304D\u308B\u3053\u3068": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "157.51px",
          height: "unset",
          top: "-60.61%",
          bottom: "37.58%",
          left: "0%",
          right: "-13.12%",
        },
        "Group 663573": {
          width: "139.23px",
          height: "9.75px",
          top: "-0.42%",
          bottom: "53.97%",
          right: "1.29%",
        },
        "Group 693572": {
          width: "141.05px",
          height: "21px",
          top: "67.36%",
          bottom: "9%",
          left: "3.8%",
          right: "20.13%",
        },
        "THEASGHT\u3068\u306F\uFF1F": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "178.3px",
          height: "1.44px",
          bottom: "50%",
        },
        "Group 66160107": {
          width: "179.84px",
          height: "2.88px",
          top: "0.24%",
          bottom: "77.65%",
          left: "0.2%",
          right: "0.06%",
        },
        "Group 66160103": {
          width: "180.32px",
          height: "13px",
          top: "21.13px",
          left: "5.1px",
        },
        "THEASIGHT\u306B\u3064\u3044\u3066": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "178.3px",
          height: "3.75px",
          bottom: "50%",
        },
        "Group 66160100": {
          width: "179.84px",
          height: "7.5px",
          top: "0%",
          bottom: "0%",
        },
        "Group 6816096": {
          width: "179.84px",
          height: "7.5px",
          bottom: "50%",
          left: "0.2%",
          right: "0.06%",
        },
        "Group 6816091": { width: "180.32px", height: "15px", left: "5.11px" },
        "IoTube\u306E\u6A5F\u80FD": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "106.05px",
          height: "8.5px",
          bottom: "50%",
        },
        "Group 6616088": {
          width: "106.96px",
          height: "17px",
          top: "0%",
          bottom: "0%",
          left: "0%",
          right: "0%",
        },
        "Group 67": {
          width: "106.96px",
          height: "17px",
          top: "71.85px",
          left: "7.05px",
        },
        "Group 82": { width: "185.43px", height: "88.85px", top: "4.02px" },
        "\u304A\u554F\u3044\u5408\u308F\u305B": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "92.87px",
          height: "8px",
          bottom: "50%",
        },
        "Group 6616081": { width: "93.67px", height: "16px" },
        "Group 72": {
          width: "93.67px",
          height: "16px",
          top: "50.03%",
          left: "0%",
          bottom: "0%",
          right: "0.27%",
        },
        "\u30B5\u30DD\u30FC\u30C8": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "92.12px",
          height: "7.5px",
          bottom: "50%",
        },
        "Group 6616074": { width: "92.92px", height: "15px" },
        "Group 6816070": { width: "92.92px", height: "15px" },
        "Group 6916065": { width: "92.92px", height: "15px", left: "1px" },
        "Group 83": { width: "93.93px", height: "32.02px", left: "191.44px" },
        "Group 86155168": { width: "285.37px", height: "92.88px" },
        "\u30B5\u30FC\u30D3\u30B9\u7D04\u6B3E": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "272.31px",
          height: "13.37px",
          bottom: "50%",
        },
        "Group 66160135": { width: "274.67px", height: "26.75px" },
        "Group 73": {
          width: "274.67px",
          height: "26.75px",
          top: "17.83px",
          left: "0px",
        },
        "\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "272.31px",
          height: "13.37px",
          bottom: "50%",
        },
        "Group 66160128": { width: "274.67px", height: "26.75px" },
        "Group 74": { width: "274.67px", height: "26.75px", top: "35.66px" },
        "\u7D04\u6B3E": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "272.31px",
          height: "13.37px",
          bottom: "50%",
        },
        "Group 66160121": { width: "274.67px", height: "26.75px" },
        "Group 71": {
          width: "274.67px",
          height: "26.75px",
          bottom: "57.14%",
          left: "0%",
        },
        "Group 84": { width: "274.67px", height: "62.41px", top: "2.88px" },
        "\u904B\u55B6\u4F1A\u793E": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "69px",
          height: "44.5px",
          bottom: "50%",
          right: "0%",
        },
        CTC: {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "57.05px",
          height: "11.87px",
          top: "18.89%",
          bottom: "67.78%",
          right: "17.32%",
        },
        "Group 66160114": { width: "69px", height: "89px" },
        "Group 70160110": {
          width: "69px",
          height: "89px",
          top: "0px",
          left: "0px",
        },
        "Group 85": {
          width: "69px",
          height: "89px",
          top: "0px",
          left: "186.34px",
        },
        "Group 87": { width: "274.67px", height: "89px" },
        "\u30D1\u30FC\u30C8\u30CA\u30FC": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          height: "44.5px",
        },
        MOYAI: {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          height: "11.87px",
        },
        "NTT\u30B3\u30DF\u30E5\u30CB\u30B1\u30FC\u30B7\u30E7\u30F3\u30BA": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          height: "11.87px",
        },
        "Group 8655620": { height: "89px" },
        "Group 88": { height: "89px" },
        "Frame 85": {
          gap: "38px",
          direction: "column",
          width: "390px",
          alignItems: "center",
          padding: "31px 18px 31px 18px",
        },
        "THEASIGHT\u00A92023 All Rights Reserved.": {
          fontSize: "8px",
          lineHeight: "9.681818008422852px",
        },
        Fotter: {
          gap: "38px",
          justifyContent: "flex-start",
          padding: "13px 0px 13px 0px",
        },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const groupSevenZeroThreeFiveSevenFiveOnClick = useNavigateAction({
    anchor: "section3rsp",
    type: "anchor",
  });
  const groupSixSixThreeFiveSevenThreeOnClick = useNavigateAction({
    anchor: "section2rsp",
    type: "anchor",
  });
  const groupSixSixOneSixZeroOneZeroSevenOnClick = useNavigateAction({
    anchor: "section1rsp2",
    type: "anchor",
  });
  const groupSixSixOneSixZeroEightEightOnClick = useNavigateAction({
    anchor: "section4rsp",
    type: "anchor",
  });
  const OnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "https://theasight.net/send.html",
  });
  const groupSevenThreeOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "https://theasight.net/info/terms.pdf",
  });
  const groupSevenFourOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "https://www.ctc-g.co.jp/utility/security_policy.html",
  });
  const cTCOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "https://www.ctc-g.co.jp/",
  });
  const mOYAIOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "https://moyai-net.com/",
  });
  const nTTOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "https://www.ntt.com/index.html",
  });
  const cursor = { style: { cursor: "pointer" }, ...restProp };
  return (
    <Flex
      gap="49px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="43px 0px 8px 51px"
      backgroundColor="rgba(36,35,57,1)"
      display="flex"
      {...getOverrideProps(overrides, "Fotter")}
      {...rest}
    >
      <Flex
        gap="3px"
        direction="row"
        width="999px"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 85")}
      >
        <Flex
          padding="0px 0px 0px 0px"
          width="385.16px"
          height="160px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 86155168")}
        >
          <View
            padding="0px 0px 0px 0px"
            width="204.24px"
            height="158.89px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="1.12px"
            left="0px"
            {...getOverrideProps(overrides, "Group 82")}
          >
            <View
              width="202.5px"
              height="26.75px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="60.71%"
              bottom="22.45%"
              left="0.85%"
              right="0%"
              padding="0px 0px 0px 0px"
              {...cursor}
              onClick={() => {
                groupSevenZeroThreeFiveSevenFiveOnClick();
              }}
              {...getOverrideProps(overrides, "Group 703575")}
            >
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="400"
                color="rgba(255,255,255,1)"
                lineHeight="16.94318199157715px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="200.76px"
                height="22.74px"
                gap="unset"
                alignItems="unset"
                position="absolute"
                top="22.99%"
                bottom="-7.99%"
                left="0.43%"
                right="0.43%"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="THEASGHT UI画面"
                {...getOverrideProps(overrides, "THEASGHT UI\u753B\u9762")}
              ></Text>
            </View>
            <View
              width="202.5px"
              height="26.75px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="43.87%"
              bottom="39.29%"
              left="0.85%"
              right="0%"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Group 693572")}
            >
              <View
                width="202.5px"
                height="12.26px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0.42%"
                bottom="53.75%"
                left="0%"
                right="0%"
                padding="0px 0px 0px 0px"
                {...cursor}
                onClick={() => {
                  groupSixSixThreeFiveSevenThreeOnClick();
                }}
                {...getOverrideProps(overrides, "Group 663573")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="16.94318199157715px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="200.76px"
                  height="22.74px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="-0.92%"
                  bottom="-84.54%"
                  left="0.86%"
                  right="0%"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="THEASGHTで実現できること"
                  {...getOverrideProps(
                    overrides,
                    "THEASGHT\u3067\u5B9F\u73FE\u3067\u304D\u308B\u3053\u3068"
                  )}
                ></Text>
              </View>
            </View>
            <View
              width="202.5px"
              height="26.75px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="49.64px"
              left="1.74px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Group 66160103")}
            >
              <View
                width="202.5px"
                height="22.3px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="-45.83%"
                bottom="62.5%"
                left="0%"
                right="0%"
                padding="0px 0px 0px 0px"
                {...cursor}
                onClick={() => {
                  groupSixSixOneSixZeroOneZeroSevenOnClick();
                }}
                {...getOverrideProps(overrides, "Group 66160107")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="16.94318199157715px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="200.76px"
                  height="15.79px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0%"
                  bottom="29.17%"
                  left="0.86%"
                  right="0%"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="THEASGHTとは？"
                  {...getOverrideProps(overrides, "THEASGHT\u3068\u306F\uFF1F")}
                ></Text>
              </View>
            </View>
            <View
              width="202.5px"
              height="37.8px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="0px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Group 6816091")}
            >
              <View
                width="202.5px"
                height="37.8px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0%"
                bottom="0%"
                left="0%"
                right="0%"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Group 6816096")}
              >
                <View
                  width="202.5px"
                  height="25.99px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="1.08%"
                  bottom="30.17%"
                  left="0%"
                  right="0%"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Group 66160100")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="700"
                    color="rgba(255,255,255,0.77)"
                    lineHeight="16.94318199157715px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    textDecoration="underline"
                    width="200.76px"
                    height="18.41px"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="0%"
                    bottom="29.17%"
                    left="0.86%"
                    right="0%"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="THEASIGHTについて"
                    {...getOverrideProps(
                      overrides,
                      "THEASIGHT\u306B\u3064\u3044\u3066"
                    )}
                  ></Text>
                </View>
              </View>
            </View>
            <View
              width="202.5px"
              height="28.98px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="129.9px"
              left="0px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Group 67")}
            >
              <View
                width="202.5px"
                height="27.87px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="23.08%"
                bottom="-19.23%"
                left="0.86%"
                right="-0.86%"
                padding="0px 0px 0px 0px"
                {...cursor}
                onClick={() => {
                  groupSixSixOneSixZeroEightEightOnClick();
                }}
                {...getOverrideProps(overrides, "Group 6616088")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="16.94318199157715px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="200.76px"
                  height="19.74px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0%"
                  bottom="29.17%"
                  left="0.86%"
                  right="0%"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="IoTubeの機能"
                  {...getOverrideProps(overrides, "IoTube\u306E\u6A5F\u80FD")}
                ></Text>
              </View>
            </View>
          </View>
          <View
            padding="0px 0px 0px 0px"
            width="152px"
            height="107.82px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="233.16px"
            {...getOverrideProps(overrides, "Group 83")}
          >
            <View
              width="151.79px"
              height="71.72px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="36.1px"
              left="0px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Group 72")}
            >
              <View
                width="151.79px"
                height="71.72px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0%"
                bottom="0%"
                left="0%"
                right="0%"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Group 6616081")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="16.94318199157715px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="150.49px"
                  height="50.8px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0%"
                  bottom="29.17%"
                  left="0.86%"
                  right="0%"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="お問い合わせ"
                  {...cursor}
                  onClick={() => {
                    OnClick();
                  }}
                  {...getOverrideProps(
                    overrides,
                    "\u304A\u554F\u3044\u5408\u308F\u305B"
                  )}
                ></Text>
              </View>
            </View>
            <View
              width="151.79px"
              height="39.45px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="0.21px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Group 6916065")}
            >
              <View
                width="151.79px"
                height="39.45px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0%"
                bottom="0%"
                left="0%"
                right="0%"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Group 6816070")}
              >
                <View
                  width="151.79px"
                  height="39.45px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0%"
                  bottom="0%"
                  left="0%"
                  right="0%"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Group 6616074")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="700"
                    color="rgba(255,255,255,0.77)"
                    lineHeight="16.94318199157715px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    textDecoration="underline"
                    width="150.49px"
                    height="27.94px"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="0%"
                    bottom="29.17%"
                    left="0.86%"
                    right="0%"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="サポート"
                    {...getOverrideProps(overrides, "\u30B5\u30DD\u30FC\u30C8")}
                  ></Text>
                </View>
              </View>
            </View>
          </View>
        </Flex>
        <Flex
          padding="0px 0px 0px 0px"
          width="340.7px"
          height="160px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 87")}
        >
          <View
            padding="0px 0px 0px 0px"
            width="234px"
            height="88px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="0px"
            {...getOverrideProps(overrides, "Group 84")}
          >
            <View
              width="233px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="31px"
              left="1px"
              padding="0px 0px 0px 0px"
              {...cursor}
              onClick={() => {
                groupSevenThreeOnClick();
              }}
              {...getOverrideProps(overrides, "Group 73")}
            >
              <View
                width="233px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0%"
                bottom="0%"
                left="0%"
                right="0%"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Group 66160135")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="16.94318199157715px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="231px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0%"
                  bottom="29.17%"
                  left="0.86%"
                  right="0%"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="サービス約款"
                  {...getOverrideProps(
                    overrides,
                    "\u30B5\u30FC\u30D3\u30B9\u7D04\u6B3E"
                  )}
                ></Text>
              </View>
            </View>
            <View
              width="233px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="64px"
              left="0px"
              padding="0px 0px 0px 0px"
              {...cursor}
              onClick={() => {
                groupSevenFourOnClick();
              }}
              {...getOverrideProps(overrides, "Group 74")}
            >
              <View
                width="233px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0%"
                bottom="0%"
                left="0%"
                right="0%"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Group 66160128")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="16.94318199157715px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="231px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0%"
                  bottom="29.17%"
                  left="0.86%"
                  right="0%"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="プライバシーポリシー"
                  {...getOverrideProps(
                    overrides,
                    "\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC"
                  )}
                ></Text>
              </View>
            </View>
            <View
              width="233px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0%"
              bottom="72.73%"
              left="0.43%"
              right="0%"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Group 71")}
            >
              <View
                width="233px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0%"
                bottom="0%"
                left="0%"
                right="0%"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Group 66160121")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="700"
                  color="rgba(255,255,255,0.77)"
                  lineHeight="16.94318199157715px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  textDecoration="underline"
                  width="231px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0%"
                  bottom="29.17%"
                  left="0.86%"
                  right="0%"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="約款"
                  {...getOverrideProps(overrides, "\u7D04\u6B3E")}
                ></Text>
              </View>
            </View>
          </View>
          <View
            padding="0px 0px 0px 0px"
            width="145.16px"
            height="159px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="1px"
            left="195.54px"
            {...getOverrideProps(overrides, "Group 85")}
          >
            <View
              width="145.16px"
              height="159px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0%"
              bottom="0%"
              left="0%"
              right="0%"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Group 70160110")}
            >
              <View
                width="145.16px"
                height="159px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0%"
                bottom="0%"
                left="0%"
                right="0%"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Group 66160114")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="700"
                  color="rgba(255,255,255,0.77)"
                  lineHeight="16.94318199157715px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  textDecoration="underline"
                  width="143.91px"
                  height="159px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0%"
                  bottom="0%"
                  left="0%"
                  right="0.86%"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="運営会社"
                  {...getOverrideProps(overrides, "\u904B\u55B6\u4F1A\u793E")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="16.94318199157715px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="128.97px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="20.13%"
                  bottom="69.18%"
                  left="0%"
                  right="11.15%"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="CTC"
                  {...cursor}
                  onClick={() => {
                    cTCOnClick();
                  }}
                  {...getOverrideProps(overrides, "CTC")}
                ></Text>
              </View>
            </View>
          </View>
        </Flex>
        <Flex
          padding="0px 0px 0px 0px"
          width="274.69px"
          height="160px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 88")}
        >
          <View
            width="274.69px"
            height="160px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Group 8655620")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="700"
              color="rgba(255,255,255,0.77)"
              lineHeight="16.94318199157715px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              textDecoration="underline"
              width="273.15px"
              height="80px"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="0.14%"
              bottom="49.86%"
              left="0.23%"
              right="0.33%"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="パートナー"
              {...getOverrideProps(overrides, "\u30D1\u30FC\u30C8\u30CA\u30FC")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="16.94318199157715px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="227.1px"
              height="21.33px"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="19%"
              bottom="67.67%"
              left="0.13%"
              right="17.19%"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="MOYAI"
              {...cursor}
              onClick={() => {
                mOYAIOnClick();
              }}
              {...getOverrideProps(overrides, "MOYAI")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="16.94318199157715px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="312.67px"
              height="21.33px"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="36.78%"
              bottom="49.89%"
              left="0%"
              right="-13.83%"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="NTTコミュニケーションズ"
              {...cursor}
              onClick={() => {
                nTTOnClick();
              }}
              {...getOverrideProps(
                overrides,
                "NTT\u30B3\u30DF\u30E5\u30CB\u30B1\u30FC\u30B7\u30E7\u30F3\u30BA"
              )}
            ></Text>
          </View>
        </Flex>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="11px"
        fontWeight="400"
        color="rgba(255,255,255,1)"
        lineHeight="13.3125px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="THEASIGHT©2023 All Rights Reserved."
        {...getOverrideProps(
          overrides,
          "THEASIGHT\u00A92023 All Rights Reserved."
        )}
      ></Text>
    </Flex>
  );
}

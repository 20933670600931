/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Icon,
  Image,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function Section3Rsp(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Rectangle 283": {},
        "THEASIGHT UI\u753B\u9762": {},
        "Group 88": {},
        "\u30B0\u30EB\u30FC\u30D7/\u30B5\u30D6\u30B0\u30EB\u30FC\u30D7/\u30C7\u30D0\u30A4\u30B9\u3000 \u306E3\u968E\u5C64\u3067\u306E\u30C7\u30D0\u30A4\u30B9\u7BA1\u7406\u304C\u53EF\u80FD":
          {},
        "\u30C7\u30D0\u30A4\u30B9\u7BA1\u74063334": {},
        "device_ui2 1": {},
        "Group 70": {},
        "\u30C7\u30D0\u30A4\u30B9\u7BA1\u74068774": {},
        "\u30C7\u30D0\u30A4\u30B9\u3092\u6307\u5B9A\u3057\u3001\u53D6\u5F97\u52D5\u753B\u65E5\u6642\u3092\u6307\u5B9A\u3059\u308B\u3053\u3068\u3067\u8996\u8074\u3082\u3057\u304F\u306F\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u304C\u53EF\u80FD":
          {},
        "\u904E\u53BB\u52D5\u753B\u53D6\u5F973335": {},
        "move_ui 1": {},
        "\u904E\u53BB\u52D5\u753B\u53D6\u5F978776": {},
        "4\u3064\u307E\u3067\u306E\u30C7\u30D0\u30A4\u30B9\u3092\u6307\u5B9A\u3057\u3066\u3001 \u30EA\u30A2\u30EB\u30BF\u30A4\u30E0\u3067\u6620\u50CF\u306E\u8996\u8074\u304C\u53EF\u80FD":
          {},
        "\u30EA\u30A2\u30EB\u30BF\u30A4\u30E0\u6620\u50CF\u8996\u8074": {},
        "live_1280_720 1": {},
        "\u30EA\u30A2\u30EB\u30BF\u30A4\u30E0": {},
        "\u5E83\u8996\u91CE\u89D2(\u6C34\u5E73\u5782\u76F4180\u00B0)\u306A\u9B5A\u773C\u6620\u50CF\u5BFE\u5FDC\u3067 \u6B7B\u89D2\u306E\u306A\u3044\u6620\u50CF\u78BA\u8A8D\u304C\u53EF\u80FD":
          {},
        "\u5E83\u8996\u91CE\u89D2\u306A\u9B5A\u773C\u6620\u50CF\u5BFE\u5FDC":
          {},
        "\u904E\u53BB\u52D5\u753B\u518D\u751F 1": {},
        "\u9B5A\u773C": {},
        "Frame 82": {},
        Section3Rsp: {},
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Rectangle 283": {
          width: "187px",
          height: "8px",
          viewBox: { minX: 0, minY: 0, width: 187, height: 8 },
          paths: [
            {
              d: "M0 0L187 0L187 8L0 8L0 0Z",
              fill: "rgba(116,171,255,1)",
              fillRule: "nonzero",
            },
          ],
          top: "15px",
        },
        "THEASIGHT UI\u753B\u9762": {
          fontSize: "20px",
          lineHeight: "24.204544067382812px",
          left: "0.5px",
        },
        "Group 88": { width: "215px", height: "24px" },
        "\u30B0\u30EB\u30FC\u30D7/\u30B5\u30D6\u30B0\u30EB\u30FC\u30D7/\u30C7\u30D0\u30A4\u30B9\u3000 \u306E3\u968E\u5C64\u3067\u306E\u30C7\u30D0\u30A4\u30B9\u7BA1\u7406\u304C\u53EF\u80FD":
          {
            fontSize: "12px",
            lineHeight: "14.522727012634277px",
            width: "189px",
            height: "45.07px",
            top: "83.65%",
            bottom: "0%",
            left: "17.58%",
            right: "17.23%",
            children:
              "\u30B0\u30EB\u30FC\u30D7/\u30B5\u30D6\u30B0\u30EB\u30FC\u30D7/\u30C7\u30D0\u30A4\u30B9\u3000 \u306E3\u968E\u5C64\u3067\u306E\u30C7\u30D0\u30A4\u30B9\u7BA1\u7406\u304C\u53EF\u80FD",
          },
        "\u30C7\u30D0\u30A4\u30B9\u7BA1\u74063334": {
          fontSize: "16px",
          lineHeight: "19.363636016845703px",
          textAlign: "center",
          width: "116px",
          height: "24.27px",
          top: "74.94%",
          bottom: "16.26%",
          left: "30%",
          right: "30%",
        },
        "device_ui2 1": {
          width: "289.94px",
          height: "192.59px",
          top: "0px",
          left: "0px",
        },
        "Group 70": { width: "289.94px", height: "192.59px" },
        "\u30C7\u30D0\u30A4\u30B9\u7BA1\u74068774": {
          width: "289.94px",
          height: "275.57px",
        },
        "\u30C7\u30D0\u30A4\u30B9\u3092\u6307\u5B9A\u3057\u3001\u53D6\u5F97\u52D5\u753B\u65E5\u6642\u3092\u6307\u5B9A\u3059\u308B\u3053\u3068\u3067\u8996\u8074\u3082\u3057\u304F\u306F\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u304C\u53EF\u80FD":
          {
            fontSize: "12px",
            lineHeight: "14.522727012634277px",
            width: "277px",
            height: "87px",
            top: "72.94%",
            bottom: "0%",
            left: "2.4%",
            right: "2.06%",
          },
        "\u904E\u53BB\u52D5\u753B\u53D6\u5F973335": {
          fontSize: "16px",
          lineHeight: "19.363636016845703px",
          textAlign: "center",
          width: "130px",
          height: "29px",
          top: "65.01%",
          bottom: "25.97%",
          left: "26.9%",
          right: "28.27%",
        },
        "move_ui 1": { width: "289.94px", height: "208.9px", left: "0px" },
        "\u904E\u53BB\u52D5\u753B\u53D6\u5F978776": {
          width: "289.94px",
          height: "321.5px",
        },
        "4\u3064\u307E\u3067\u306E\u30C7\u30D0\u30A4\u30B9\u3092\u6307\u5B9A\u3057\u3066\u3001 \u30EA\u30A2\u30EB\u30BF\u30A4\u30E0\u3067\u6620\u50CF\u306E\u8996\u8074\u304C\u53EF\u80FD":
          {
            fontSize: "12px",
            lineHeight: "14.522727012634277px",
            width: "187px",
            height: "84px",
            top: "68.42%",
            bottom: "0%",
            left: "13.43%",
            right: "16.79%",
          },
        "\u30EA\u30A2\u30EB\u30BF\u30A4\u30E0\u6620\u50CF\u8996\u8074": {
          fontSize: "16px",
          lineHeight: "19.363636016845703px",
          textAlign: "center",
          width: "243px",
          top: "58.47%",
          bottom: "34.39%",
          left: "1.61%",
          right: "7.72%",
        },
        "live_1280_720 1": { width: "268px", height: "134.46px" },
        "\u30EA\u30A2\u30EB\u30BF\u30A4\u30E0": {
          width: "268px",
          height: "266px",
        },
        "\u5E83\u8996\u91CE\u89D2(\u6C34\u5E73\u5782\u76F4180\u00B0)\u306A\u9B5A\u773C\u6620\u50CF\u5BFE\u5FDC\u3067 \u6B7B\u89D2\u306E\u306A\u3044\u6620\u50CF\u78BA\u8A8D\u304C\u53EF\u80FD":
          {
            fontSize: "12px",
            lineHeight: "14.522727012634277px",
            width: "242px",
            height: "unset",
            top: "86.36%",
            bottom: "0%",
            left: "7.46%",
            right: "2.24%",
          },
        "\u5E83\u8996\u91CE\u89D2\u306A\u9B5A\u773C\u6620\u50CF\u5BFE\u5FDC": {
          fontSize: "16px",
          lineHeight: "19.363636016845703px",
          textAlign: "center",
          width: "238.3px",
          height: "26.48px",
          top: "74.1%",
          bottom: "13.86%",
          left: "7.51%",
          right: "3.57%",
        },
        "\u904E\u53BB\u52D5\u753B\u518D\u751F 1": {
          width: "268px",
          height: "128.92px",
          left: "0px",
        },
        "\u9B5A\u773C": { width: "268px", height: "219.97px" },
        "Frame 82": { gap: "4px", padding: "67px 50px 67px 50px" },
        Section3Rsp: { padding: "0px 0px 0px 0px" },
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 129px 0px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      id="section3rsp"
      {...getOverrideProps(overrides, "Section3Rsp")}
      {...rest}
    >
      <Flex
        gap="122px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="97px 24px 97px 24px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 82")}
      >
        <Flex
          padding="0px 0px 0px 0px"
          width="449px"
          height="58.5px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 88")}
        >
          <Icon
            width="404px"
            height="18px"
            viewBox={{ minX: 0, minY: 0, width: 404, height: 18 }}
            paths={[
              {
                d: "M0 0L404 0L404 20L0 20L0 0Z",
                fill: "rgba(116,171,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="29.5px"
            left="0px"
            {...getOverrideProps(overrides, "Rectangle 283")}
          ></Icon>
          <Text
            fontFamily="Inter"
            fontSize="40px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="48.409088134765625px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            //top="0px"
            //left="74px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="THEASIGHT UI画面"
            {...getOverrideProps(overrides, "THEASIGHT UI\u753B\u9762")}
          ></Text>
        </Flex>
        <Flex
          padding="0px 0px 0px 0px"
          width="985.97px"
          height="331.87px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(
            overrides,
            "\u30C7\u30D0\u30A4\u30B9\u7BA1\u74068774"
          )}
        >
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="29.045454025268555px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="434.54px"
            height="55.04px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="48.76%"
            bottom="34.65%"
            left="55.93%"
            right="0%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="グループ ー サブグループ ー デバイス　&#xA;の3階層でのデバイス管理が可能"
            {...getOverrideProps(
              overrides,
              "\u30B0\u30EB\u30FC\u30D7/\u30B5\u30D6\u30B0\u30EB\u30FC\u30D7/\u30C7\u30D0\u30A4\u30B9\u3000 \u306E3\u968E\u5C64\u3067\u306E\u30C7\u30D0\u30A4\u30B9\u7BA1\u7406\u304C\u53EF\u80FD"
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="38px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="266px"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="31.79%"
            bottom="56.46%"
            left="65.62%"
            right="14.91%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="デバイス管理"
            {...getOverrideProps(
              overrides,
              "\u30C7\u30D0\u30A4\u30B9\u7BA1\u74063334"
            )}
          ></Text>
          <View
            padding="0px 0px 0px 0px"
            width="482.82px"
            height="331.87px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="0px"
            {...getOverrideProps(overrides, "Group 70")}
          >
            <Image
              width="100%"
              height="100%"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0%"
              bottom="0%"
              left="0%"
              right="0%"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://theasight.net/website_image/device_ui2.png"
              {...getOverrideProps(overrides, "device_ui2 1")}
            ></Image>
          </View>
        </Flex>
        <Flex
          padding="0px 0px 0px 0px"
          width="985.97px"
          height="331.87px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(
            overrides,
            "\u904E\u53BB\u52D5\u753B\u53D6\u5F978776"
          )}
        >
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="29.045454025268555px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="476.71px"
            height="83.55px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="53.49%"
            bottom="21.34%"
            left="0%"
            right="51.65%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="デバイスを指定し、取得動画日時を指定することで視聴もしくはダウンロードが可能"
            {...getOverrideProps(
              overrides,
              "\u30C7\u30D0\u30A4\u30B9\u3092\u6307\u5B9A\u3057\u3001\u53D6\u5F97\u52D5\u753B\u65E5\u6642\u3092\u6307\u5B9A\u3059\u308B\u3053\u3068\u3067\u8996\u8074\u3082\u3057\u304F\u306F\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u304C\u53EF\u80FD"
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="38.727272033691406px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="266px"
            height="40px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="34.84%"
            bottom="53.11%"
            left="12.17%"
            right="60.85%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="過去動画取得"
            {...getOverrideProps(
              overrides,
              "\u904E\u53BB\u52D5\u753B\u53D6\u5F973335"
            )}
          ></Text>
          <Image
            width="460.41px"
            height="331.87px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="525.56px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://theasight.net/website_image/move_ui.png"
            {...getOverrideProps(overrides, "move_ui 1")}
          ></Image>
        </Flex>
        <Flex
          padding="0px 0px 0px 0px"
          width="986px"
          height="249px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(
            overrides,
            "\u30EA\u30A2\u30EB\u30BF\u30A4\u30E0"
          )}
        >
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="29.045454025268555px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="402px"
            height="57px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="44.88%"
            bottom="32.23%"
            left="59.23%"
            right="0%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="4つまでのデバイスを指定して、&#xA;リアルタイムで映像の視聴が可能"
            {...getOverrideProps(
              overrides,
              "4\u3064\u307E\u3067\u306E\u30C7\u30D0\u30A4\u30B9\u3092\u6307\u5B9A\u3057\u3066\u3001 \u30EA\u30A2\u30EB\u30BF\u30A4\u30E0\u3067\u6620\u50CF\u306E\u8996\u8074\u304C\u53EF\u80FD"
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="38.727272033691406px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="357px"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="22.39%"
            bottom="61.95%"
            left="60.34%"
            right="3.45%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="リアルタイム映像視聴"
            {...getOverrideProps(
              overrides,
              "\u30EA\u30A2\u30EB\u30BF\u30A4\u30E0\u6620\u50CF\u8996\u8074"
            )}
          ></Text>
          <Image
            width="505.49px"
            height="249px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="0px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://theasight.net/website_image/live_1280_720.gif"
            {...getOverrideProps(overrides, "live_1280_720 1")}
          ></Image>
        </Flex>
        <Flex
          padding="0px 0px 0px 0px"
          width="985.97px"
          height="238.11px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "\u9B5A\u773C")}
        >
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="29.045454025268555px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="496.45px"
            height="103.89px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="42.55%"
            bottom="13.82%"
            left="0%"
            right="49.65%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="広視野角(水平垂直180°)な魚眼映像対応で&#xA;死角のない映像確認が可能"
            {...getOverrideProps(
              overrides,
              "\u5E83\u8996\u91CE\u89D2(\u6C34\u5E73\u5782\u76F4180\u00B0)\u306A\u9B5A\u773C\u6620\u50CF\u5BFE\u5FDC\u3067 \u6B7B\u89D2\u306E\u306A\u3044\u6620\u50CF\u78BA\u8A8D\u304C\u53EF\u80FD"
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="900"
            color="rgba(0,0,0,1)"
            lineHeight="38.727272033691406px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="19.22%"
            bottom="64.4%"
            left="3.14%"
            right="61.16%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="広視野角な魚眼映像対応"
            {...getOverrideProps(
              overrides,
              "\u5E83\u8996\u91CE\u89D2\u306A\u9B5A\u773C\u6620\u50CF\u5BFE\u5FDC"
            )}
          ></Text>
          <Image
            width="486.62px"
            height="238.11px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="499.35px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://theasight.net/website_image/%E9%81%8E%E5%8E%BB%E5%8B%95%E7%94%BB%E5%86%8D%E7%94%9F.gif"
            {...getOverrideProps(
              overrides,
              "\u904E\u53BB\u52D5\u753B\u518D\u751F 1"
            )}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Image, View, useBreakpointValue } from "@aws-amplify/ui-react";
export default function Section6Rsp(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Section6-img 1": {},
        "toiBtn 1": {},
        ToiBtn: {},
        "Group 81": {},
        Section6Rsp: {},
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Section6-img 1": {
          width: "390px",
          height: "258.02px",
          objectFit: "cover",
        },
        "toiBtn 1": {
          width: "100%",
          height: "100.04%",
          top: "0%",
          bottom: "-0.04%",
          left: "0%",
          right: "0%",
        },
        ToiBtn: { width: "225px", height: "29px", top: "215px", left: "91px" },
        "Group 81": { width: "390px", height: "258.02px" },
        Section6Rsp: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const toiBtnOneOnClick = useNavigateAction({
    type: "url",
    url: "https://theasight.net/send.html",
    target: "_blank",
  });
  const cursor = { style: { cursor: "pointer" }, ...restProp };
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "Section6Rsp")}
      {...rest}
    >
      <Flex
        padding="0px 0px 0px 0px"
        width="100%"
        height="auto"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
       //position="relative"
        {...getOverrideProps(overrides, "Group 81")}
      >
        <Image
          width="calc(100% - 0px)"
          height="auto"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
         // position="absolute"
          top="0px"
          left="0px"
          right="0px"
          padding="0px 0px 0px 0px"
          objectFit="unset"
          src="https://theasight.net/website_image/Section6-img.png"
          {...getOverrideProps(overrides, "Section6-img 1")}
        ></Image>
        <View
          width="100%"
          //height="98.61px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          borderRadius="39px"
          padding="0px 0px 0px 0px"
          top= "81%"
          textAlign="center"
          {...getOverrideProps(overrides, "ToiBtn")}
        >
          <Image
            width="530px"
            display="inline-block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            top="59.83%"
            bottom="-24.74%"
            left="17.82%"
            right="17.76%"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            {...cursor}
            src="https://theasight.net/website_image/toiBtn.png"
            onClick={() => {
              toiBtnOneOnClick();
            }}
            {...getOverrideProps(overrides, "toiBtn 1")}
          ></Image>
        </View>
      </Flex>
    </Flex>
  );
}

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Icon,
  Image,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function Section5Rsp(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Rectangle 283": {},
        "\u5354\u696D\u30D1\u30FC\u30C8\u30CA\u30FC": {},
        "Group 94": {},
        "Rectangle 33032019": {},
        "211015_docomobusiness_rgb_color 2": {},
        docomo: {},
        "Rectangle 33032022": {},
        "MOYAI\u30ED\u30B4 1": {},
        moyai: {},
        "Frame 410": {},
        Section5Rsp: {},
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Rectangle 283": {
          width: "113px",
          height: "8px",
          viewBox: { minX: 0, minY: 0, width: 113, height: 8 },
          paths: [
            {
              d: "M0 0L113 0L113 8L0 8L0 0Z",
              fill: "rgba(116,171,255,1)",
              fillRule: "nonzero",
            },
          ],
          top: "11px",
        },
        "\u5354\u696D\u30D1\u30FC\u30C8\u30CA\u30FC": {
          fontSize: "16px",
          lineHeight: "19.363636016845703px",
          width: "116px",
          height: "21px",
          left: "2.5px",
        },
        "Group 94": { width: "118.5px", height: "21px" },
        "Rectangle 33032019": { width: "117.5px", height: "53.05px" },
        "211015_docomobusiness_rgb_color 2": {
          width: "117.5px",
          height: "53.05px",
        },
        docomo: { width: "117.5px", height: "53.05px" },
        "Rectangle 33032022": { width: "117.5px", height: "53.05px" },
        "MOYAI\u30ED\u30B4 1": {
          width: "99.82px",
          height: "30.52px",
          top: "14.83px",
          left: "8.84px",
        },
        moyai: { width: "117.5px", height: "53.05px" },
        "Frame 410": { gap: "22px", width: "unset" },
        Section5Rsp: {
          gap: "10px",
          width: "365px",
          height: "unset",
          justifyContent: "center",
          padding: "20px 0px 20px 0px",
          margin: "0 auto"
        },
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const twoOneOneZeroOneFiveUnderScoredocomobusinessUnderScorergbUnderScorecolorTwoOnClick =
    useNavigateAction({
      type: "url",
      url: "https://www.ntt.com/index.html",
      target: "_blank",
    });
  const mOYAIOneOnClick = useNavigateAction({
    type: "url",
    url: "https://moyai-net.com/",
    target: "_blank",
  });
  return (
    <Flex
      gap="101px"
      direction="column"
      width="unset"
      height="531px"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="75px 0px 63px 0px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "Section5Rsp")}
      {...rest}
    >
      <Flex
        padding="0px 0px 0px 0px"
        width="374px"
        height="53px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Group 94")}
      >
        <Icon
          width="374px"
          height="23px"
          viewBox={{ minX: 0, minY: 0, width: 374, height: 29 }}
          paths={[
            {
              d: "M0 0L374 0L374 20L0 20L0 0Z",
              fill: "rgba(116,171,255,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="24px"
          left="0px"
          {...getOverrideProps(overrides, "Rectangle 283")}
        ></Icon>
        <Text
          fontFamily="Inter"
          fontSize="40px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="48.409088134765625px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="49px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="協業パートナー"
          {...getOverrideProps(
            overrides,
            "\u5354\u696D\u30D1\u30FC\u30C8\u30CA\u30FC"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="196px"
        direction="row"
        width="742px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 410")}
      >
        <Flex
          padding="0px 0px 0px 0px"
          width="273px"
          height="123.25px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "docomo")}
        >
          <View
            width="273px"
            height="123.25px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="0px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Rectangle 33032019")}
          ></View>
          <Image
            width="273px"
            height="123.25px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="0px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://theasight.net/website_image/docomo.png"
            onClick={() => {
              twoOneOneZeroOneFiveUnderScoredocomobusinessUnderScorergbUnderScorecolorTwoOnClick();
            }}
            {...getOverrideProps(
              overrides,
              "211015_docomobusiness_rgb_color 2"
            )}
          ></Image>
        </Flex>
        <Flex
          padding="0px 0px 0px 0px"
          width="273px"
          height="123.25px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "moyai")}
        >
          <View
            width="273px"
            height="123.25px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="0px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Rectangle 33032022")}
          ></View>
          <Image
            width="231.92px"
            height="70.9px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="34.46px"
            left="20.54px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://theasight.net/website_image/moyai.png"
            onClick={() => {
              mOYAIOneOnClick();
            }}
            {...getOverrideProps(overrides, "MOYAI\u30ED\u30B4 1")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Image,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function Section1Rsp(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "\u3053\u3093\u306A\u304A\u60A9\u307F\u306F\u3042\u308A\u307E\u305B\u3093\u304B\uFF1F":
          {},
        "Section1-img 2": {},
        "\u3053\u3093\u306A\u8AB2\u984C\u30FB\u304A\u60A9\u307F\u3042\u308A\u307E\u305B\u3093\u304B\uFF1F":
          {},
        Section1Rsp: {},
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "\u3053\u3093\u306A\u304A\u60A9\u307F\u306F\u3042\u308A\u307E\u305B\u3093\u304B\uFF1F":
          {
            fontSize: "20px",
            lineHeight: "24px",
            textAlign: "left",
            width: "unset",
            top: "0",
            left: "0",
            right:"0",
            textAlign: "center",
            padding: "25px 0px 0px"
          },
        "Section1-img 2": {
          width: "363px",
          height: "309px",
          top: "37px",
          left: "15px",
        },
        "\u3053\u3093\u306A\u8AB2\u984C\u30FB\u304A\u60A9\u307F\u3042\u308A\u307E\u305B\u3093\u304B\uFF1F":
          { width: "390px", height: "401px" },
        Section1Rsp: { height: "401px", justifyContent: "space-between" },
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="944px"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(239,242,249,1)"
      display="flex"
      {...getOverrideProps(overrides, "Section1Rsp")}
      {...rest}
    >
      <View
        width="1019px"
        height="944px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(239,242,249,1)"
        {...getOverrideProps(
          overrides,
          "\u3053\u3093\u306A\u8AB2\u984C\u30FB\u304A\u60A9\u307F\u3042\u308A\u307E\u305B\u3093\u304B\uFF1F"
        )}
      >
        <Text
          fontFamily="Inter"
          fontSize="40px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="48.409088134765625px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="615px"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="115px"
          left="202px"
          right="202px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="こんなお悩みはありませんか？"
          {...getOverrideProps(
            overrides,
            "\u3053\u3093\u306A\u304A\u60A9\u307F\u306F\u3042\u308A\u307E\u305B\u3093\u304B\uFF1F"
          )}
        ></Text>
        <Image
          width="calc(100% - 348px)"
          height="calc(100% - 373px)"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="154px"
          bottom="219px"
          left="174px"
          right="174px"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://theasight.net/website_image/Section1-img.png"
          {...getOverrideProps(overrides, "Section1-img 2")}
        ></Image>
      </View>
    </Flex>
  );
}

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Image,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function MainRsp(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        smartImage: { display: "block", top: "1px", left: "0px" },
        pcImage: {
          height: "216px",
          display: "none",
          top: "81px",
          objectFit: "cover",
        },
        "\u5927\u91CF\u306EAI\u30AB\u30E1\u30E9\u3092\u30AF\u30E9\u30A6\u30C9\u4E0A\u304B\u3089\u5B89\u5168\u30FB\u7C21\u5358\u306B\u904B\u7528\u3067\u304D\u307E\u3059\u3002 AI\u304C\u6620\u50CF\u89E3\u6790\u3057\u305F\u5C5E\u6027\u30C7\u30FC\u30BF\u3084\u30BB\u30F3\u30B5\u30FC\u60C5\u5831\u3092\u6D3B\u7528\u3067\u304D\u308B\u30D7\u30E9\u30C3\u30C8\u30D5\u30A9\u30FC\u30E0\u3067\u3059\u3002":
          { fontSize: "14px", lineHeight: "21px", width: "346px" },
        "Frame 76": {
          width: "385px",
          height: "116px",
          padding: "0px 27px 0px 42px",
        },
        "main-THEASIGHT-subtext": {
          width: "390px",
          height: "128px",
          backgroundColor: "rgba(2,7,26,1)",
        },
        "Group 76": {
          width: "390px",
          height: "128px",
          top: "86px",
          left: "0px",
        },
        "\u300ETHEASIGHT\u300F": {
          fontSize: "32px",
          lineHeight: "38.727272033691406px",
          width: "unset",
          height: "unset",
          top: "34.18%",
          bottom: "16.46%",
          left: "3.04%",
          right: "3.42%",
        },
        "\u30C6\u30A2\u30B5\u30A4\u30C8": {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
          letterSpacing: "9.09px",
          width: "132px",
          height: "unset",
          top: "12.66%",
          bottom: "65.82%",
          left: "27.38%",
          right: "22.43%",
        },
        "main-THEASIGHT": {
          width: "263px",
          height: "79px",
          top: "calc(50% - 39.5px - 67.5px)",
          left: "calc(50% - 131.5px - -0.5px)",
        },
        "Group 97": {
          width: "390px",
          height: "214px",
          top: "335px",
          left: "0px",
        },
        MainRsp: { width: "390px", height: "549px" },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        smartImage: {},
        pcImage: {},
        "\u5927\u91CF\u306EAI\u30AB\u30E1\u30E9\u3092\u30AF\u30E9\u30A6\u30C9\u4E0A\u304B\u3089\u5B89\u5168\u30FB\u7C21\u5358\u306B\u904B\u7528\u3067\u304D\u307E\u3059\u3002 AI\u304C\u6620\u50CF\u89E3\u6790\u3057\u305F\u5C5E\u6027\u30C7\u30FC\u30BF\u3084\u30BB\u30F3\u30B5\u30FC\u60C5\u5831\u3092\u6D3B\u7528\u3067\u304D\u308B\u30D7\u30E9\u30C3\u30C8\u30D5\u30A9\u30FC\u30E0\u3067\u3059\u3002":
          {},
        "Frame 76": {},
        "main-THEASIGHT-subtext": {},
        "Group 76": {},
        "\u300ETHEASIGHT\u300F": {},
        "\u30C6\u30A2\u30B5\u30A4\u30C8": {},
        "main-THEASIGHT": {},
        "Group 97": {},
        MainRsp: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const cursor = { style: { cursor: "pointer" }, ...restProp };
  return (
    <View
      width="100%"
      height="auto"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      marginTop="-6px"
      max-width={1920}
      {...getOverrideProps(overrides, "MainRsp")}
      {...rest}
    >
      <Image
        width="390px"
        height="419.81px"
        display="none"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="107px"
        left="801px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="https://theasight.net/website_image/smartImage.jpg"
        {...getOverrideProps(overrides, "smartImage")}
      ></Image>
      <Image
        width="calc(100% - 0px)"
        height="auto"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        //position="absolute"
        top="0px"
        left="0px"
        right="0px"
        padding="0px 0px 0px 0px"
        objectFit="unset"
        src="https://theasight.net/website_image/mainimage.jpg"
        {...getOverrideProps(overrides, "pcImage")}
      ></Image>
      <View
        padding="0px 0px 0px 0px"
        width="646px"
        height="159px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="74%"
        left="23px"
        {...getOverrideProps(overrides, "Group 97")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="615px"
          height="77px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="82px"
          left="31px"
          {...getOverrideProps(overrides, "Group 76")}
        >
          <Flex
            gap="45px"
            direction="column"
            width="615px"
            height="77px"
            justifyContent="flex-start"
            alignItems="center"
            position="absolute"
            top="0px"
            left="0px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(0,0,0,0.37)"
            display="flex"
            {...getOverrideProps(overrides, "main-THEASIGHT-subtext")}
          >
            <Flex
              gap="0"
              direction="column"
              width="612px"
              height="77px"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 76")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(255,255,255,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="大量のAIカメラをクラウド上から安全・簡単に運用できます。&#xD;&#xA;AIが映像解析した属性データやセンサー情報を活用できるプラットフォームです。"
                {...getOverrideProps(
                  overrides,
                  "\u5927\u91CF\u306EAI\u30AB\u30E1\u30E9\u3092\u30AF\u30E9\u30A6\u30C9\u4E0A\u304B\u3089\u5B89\u5168\u30FB\u7C21\u5358\u306B\u904B\u7528\u3067\u304D\u307E\u3059\u3002 AI\u304C\u6620\u50CF\u89E3\u6790\u3057\u305F\u5C5E\u6027\u30C7\u30FC\u30BF\u3084\u30BB\u30F3\u30B5\u30FC\u60C5\u5831\u3092\u6D3B\u7528\u3067\u304D\u308B\u30D7\u30E9\u30C3\u30C8\u30D5\u30A9\u30FC\u30E0\u3067\u3059\u3002"
                )}
              ></Text>
            </Flex>
          </Flex>
        </View>
        <View
          width="479px"
          height="82px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "main-THEASIGHT")}
        >
          <Text
            fontFamily="Inter"
            fontSize="40px"
            fontWeight="400"
            color="rgba(236,236,237,1)"
            lineHeight="48.409088134765625px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="318px"
            height="33px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="50%"
            bottom="9.76%"
            left="3.97%"
            right="29.65%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="『THEASIGHT』"
            {...getOverrideProps(overrides, "\u300ETHEASIGHT\u300F")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(236,236,237,1)"
            lineHeight="19.363636016845703px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="10.41px"
            width="152.57px"
            height="12.98px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="26.83%"
            bottom="57.34%"
            left="21.92%"
            right="46.23%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="テアサイト"
            {...getOverrideProps(overrides, "\u30C6\u30A2\u30B5\u30A4\u30C8")}
          ></Text>
        </View>
      </View>
    </View>
  );
}

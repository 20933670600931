/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Icon,
  Image,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function Section2Rsp(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Rectangle 283": {
          width: "277px",
          height: "8px",
          viewBox: { minX: 0, minY: 0, width: 277, height: 8 },
          paths: [
            {
              d: "M0 0L277 0L277 8L0 8L0 0Z",
              fill: "rgba(116,171,255,1)",
              fillRule: "nonzero",
            },
          ],
          top: "15.14px",
          left: "4px",
        },
        "THEASIGHT\u3067\u5B9F\u73FE\u3067\u304D\u308B\u3053\u3068": {
          fontSize: "20px",
          lineHeight: "24.204544067382812px",
          width: "286px",
          height: "24px",
          top: "0%",
          bottom: "0%",
          left: "0%",
          right: "0%",
        },
        "Group 89": {
          width: "286px",
          height: "24px",
          top: "5px",
          left: "-20px",
        },
        "Section2-title": { width: "246px", height: "28px" },
        "Section2-img 2": {
          width: "346px",
          height: "139.27px",
          display: "none",
        },
        "Section2-smrt 1": {
          width: "100%",
          height: "auto",
          display: "block",
        },
        Section2Rsp: {
          gap: "28px",
          height: "557px",
          justifyContent: "center",
          padding: "21px 10px 21px 10px",
        },
      },
    },
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Rectangle 283": {},
        "THEASIGHT\u3067\u5B9F\u73FE\u3067\u304D\u308B\u3053\u3068": {},
        "Group 89": {},
        "Section2-title": {},
        "Section2-img 2": {},
        "Section2-smrt 1": {},
        Section2Rsp: {},
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="40px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="110px 0px 71px 0px"
      backgroundColor="rgba(247 248 248)"
      display="flex"
      id="section2rsp"
      {...getOverrideProps(overrides, "Section2Rsp")}
      {...rest}
    >
      <View
        width="927px"
        height="103px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Section2-title")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="798px"
          height="62px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="37px"
          left="76.5px"
          {...getOverrideProps(overrides, "Group 89")}
        >
          <Icon
            width="590px"
            height="24px"
            viewBox={{ minX: 0, minY: 0, width: 676, height: 24 }}
            paths={[
              {
                d: "M0 8.9407e-08L676 8.9407e-08L676 20L0 20L0 8.9407e-08Z",
                fill: "rgba(116,171,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="30px"
            bottom="0px"
            left="104px"
            right="50px"
            {...getOverrideProps(overrides, "Rectangle 283")}
          ></Icon>
          <Text
            fontFamily="Inter"
            fontSize="40px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="48.409088134765625px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="798px"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0px"
            bottom="14px"
            left="0px"
            right="0px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="THEASIGHTで実現できること"
            {...getOverrideProps(
              overrides,
              "THEASIGHT\u3067\u5B9F\u73FE\u3067\u304D\u308B\u3053\u3068"
            )}
          ></Text>
        </View>
      </View>
      <Image
        width="86%"
        height="auto"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="https://theasight.net/website_image/Section2-img.png"
        {...getOverrideProps(overrides, "Section2-img 2")}
      ></Image>
      <Image
        width="118px"
        height="140px"
        display="none"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="https://theasight.net/website_image/Section2-smrt.png"
        {...getOverrideProps(overrides, "Section2-smrt 1")}
      ></Image>
    </Flex>
  );
}

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Icon,
  Image,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function Section4Rsp(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "medium" },
      overrides: {
        "Rectangle 331": {},
        "Group 95": {},
        "IoTube\u306E\u6A5F\u80FD": {},
        "\u7C21\u5358\u8A2D\u7F6E-LED\u86CD\u5149\u706F\u3068\u5165\u308C\u66FF\u3048\u308B\u3060\u3051\uFF01":
          {},
        "Ellipse 173861": {},
        "\u9B5A\u773C\u30EC\u30F3\u30BA\u642D\u8F09\u30AB\u30E1\u30E9": {},
        "Group 78": {},
        "Group 25": {},
        "Ellipse 173864": {},
        "\u30A8\u30C3\u30B8AI \u642D\u8F09": {},
        "Group 79": {},
        "Group 26": {},
        "Ellipse 173867": {},
        "SIM\u642D\u8F09": {},
        "Group 80": {},
        "Group 27": {},
        "Ellipse 178522": {},
        "\u53CC\u65B9\u5411\u901A\u8A71\u5BFE\u5FDC": {},
        "Group 778521": {},
        "Group 33": {},
        "Section4-img2 1": {},
        "Ellipse 178513": {},
        "\u5404\u7A2E\u30BB\u30F3\u30B5\u30FC \u642D\u8F09": {},
        "Group 778516": {},
        "Group 32": {},
        main: {},
        "Frame 413": {},
        "Frame 86": {},
        "Rectangle 332": {},
        "Group 96": {},
        Section4Rsp: {},
      },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        "Rectangle 331": {
          width: "394px",
          height: "54px",
          viewBox: { minX: 0, minY: 0, width: 394, height: 54 },
          paths: [
            {
              d: "M0 0L394 0L394 54L0 9.2342L0 0Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ],
          left: "0px",
        },
        "Group 95": { height: "54px" },
        "IoTube\u306E\u6A5F\u80FD": {
          fontSize: "16px",
          lineHeight: "19.363636016845703px",
          top: "0px",
          left: "117px",
        },
        "\u7C21\u5358\u8A2D\u7F6E-LED\u86CD\u5149\u706F\u3068\u5165\u308C\u66FF\u3048\u308B\u3060\u3051\uFF01":
          {
            fontSize: "14px",
            lineHeight: "16.94318199157715px",
            top: "26px",
            left: "54.5px",
          },
        "Ellipse 173861": {
          width: "83px",
          height: "83px",
          viewBox: { minX: 0, minY: 0, width: 83, height: 83 },
          paths: [
            {
              d: "M83 41.5C83 64.4198 64.4198 83 41.5 83C18.5802 83 0 64.4198 0 41.5C0 18.5802 18.5802 0 41.5 0C64.4198 0 83 18.5802 83 41.5Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ],
          left: "0px",
        },
        "\u9B5A\u773C\u30EC\u30F3\u30BA\u642D\u8F09\u30AB\u30E1\u30E9": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "51px",
          height: "35px",
          top: "27px",
          left: "16px",
        },
        "Group 78": { width: "83px", height: "83px" },
        "Group 25": { width: "83px", height: "83px", top: "1px" },
        "Ellipse 173864": {
          width: "83px",
          height: "83px",
          viewBox: { minX: 0, minY: 0, width: 83, height: 83 },
          paths: [
            {
              d: "M83 41.5C83 64.4198 64.4198 83 41.5 83C18.5802 83 0 64.4198 0 41.5C0 18.5802 18.5802 0 41.5 0C64.4198 0 83 18.5802 83 41.5Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ],
          left: "0px",
        },
        "\u30A8\u30C3\u30B8AI \u642D\u8F09": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "42.1px",
          height: "24.5px",
          top: "30px",
          left: "20px",
        },
        "Group 79": { width: "83px", height: "83px" },
        "Group 26": {
          width: "83px",
          height: "83px",
          top: "1px",
          left: "108px",
        },
        "Ellipse 173867": {
          width: "83px",
          height: "83px",
          viewBox: { minX: 0, minY: 0, width: 83, height: 83 },
          paths: [
            {
              d: "M83 41.5C83 64.4198 64.4198 83 41.5 83C18.5802 83 0 64.4198 0 41.5C0 18.5802 18.5802 0 41.5 0C64.4198 0 83 18.5802 83 41.5Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ],
          left: "0px",
        },
        "SIM\u642D\u8F09": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "46px",
          height: "19px",
          top: "36px",
          left: "26px",
        },
        "Group 80": { width: "83px", height: "83px" },
        "Group 27": {
          width: "83px",
          height: "83px",
          top: "0px",
          left: "218px",
        },
        "Ellipse 178522": {
          width: "79.57px",
          height: "83px",
          viewBox: { minX: 0, minY: 0, width: 79.57000732421875, height: 83 },
          paths: [
            {
              d: "M79.57 41.5C79.57 64.4198 61.7576 83 39.785 83C17.8123 83 0 64.4198 0 41.5C0 18.5802 17.8123 0 39.785 0C61.7576 0 79.57 18.5802 79.57 41.5Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ],
          left: "0px",
        },
        "\u53CC\u65B9\u5411\u901A\u8A71\u5BFE\u5FDC": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "83px",
          height: "17.05px",
          top: "38px",
          left: "0px",
        },
        "Group 778521": { width: "83px", height: "83px" },
        "Group 33": {
          width: "83px",
          height: "83px",
          top: "108px",
          left: "104px",
        },
        "Section4-img2 1": {
          width: "162.43px",
          height: "191.82px",
          top: "58.18px",
          left: "177.57px",
        },
        "Ellipse 178513": {
          width: "83px",
          height: "83px",
          viewBox: { minX: 0, minY: 0, width: 83, height: 83 },
          paths: [
            {
              d: "M83 41.5C83 64.4198 64.4198 83 41.5 83C18.5802 83 0 64.4198 0 41.5C0 18.5802 18.5802 0 41.5 0C64.4198 0 83 18.5802 83 41.5Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ],
          left: "0px",
        },
        "\u5404\u7A2E\u30BB\u30F3\u30B5\u30FC \u642D\u8F09": {
          fontSize: "10px",
          lineHeight: "12.102272033691406px",
          width: "73px",
          height: "27px",
          top: "34px",
          left: "7px",
        },
        "Group 778516": { width: "83px", height: "83px" },
        "Group 32": {
          width: "83px",
          height: "83px",
          top: "108px",
          left: "8px",
        },
        main: { width: "340px", height: "250px", left: "0px" },
        "Frame 413": {
          width: "340px",
          height: "250px",
          top: "80.5px",
          left: "0px",
        },
        "Frame 86": { width: "340px", height: "321px" },
        "Rectangle 332": {
          width: "394px",
          height: "53px",
          viewBox: { minX: 0, minY: 0, width: 394, height: 53 },
          paths: [
            {
              d: "M0 29.0682L394 0L394 53L0 53L0 29.0682Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ],
          left: "0px",
        },
        "Group 96": { height: "53px" },
        Section4Rsp: {
          gap: "46px",
          width: "100%",
          height: "518px",
          //padding: "0px 0px 97px 0px",
          paddingTop:"25%" 
        },
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s"}, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const boderStyleBottom={style: {
   borderStyle: "solid", 
   borderWidth:"98px 0 0 99vw" ,
   borderColor:"transparent transparent transparent #fff"
  }, ...restProp };
  const boderStyleTop={style: {
    borderStyle: "solid", 
    borderWidth:"0px 0px 91px 99vw" ,
    borderColor:"transparent transparent transparent #fff"
   }, ...restProp };
  return (
    <Flex
      gap="141px"
      direction="column"
      width="unset"
      //height="80vh" // 1171px
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="5% 0px"
      backgroundImage="linear-gradient(0deg, rgba(43,88,118,1), rgba(78,67,118,1))"
      display="flex"
      id="section4rsp"
      {...getOverrideProps(overrides, "Section4Rsp")}
      {...rest}
    >
      <Flex
      /*
        padding="0px 0px 0px 0px"
        width="unset"
        height="106px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        */
        position="absolute"
        top="-1px"
        left="0"
        width="0"
        height="0"
        {...boderStyleTop}
        {...getOverrideProps(overrides, "Group 95")}
      >
        <Icon
          width="1035px"
          height="106px"
          viewBox={{ minX: 0, minY: 0, width: 1035, height: 106 }}
          paths={[
            {
              d: "M0 0L1035 0L1035 106L0 57.5L0 0Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ]}
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0%"
          right="0%"
          marginTop="50px"
          {...getOverrideProps(overrides, "Rectangle 331")}
        ></Icon>
      </Flex>
      <View
        width="1035px"
        height="629px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 86")}
      >
        <Text
          fontFamily="Inter"
          fontSize="40px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="48.409088134765625px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="64px"
          left="calc(50% - 126.5px - 16px)"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="IoTubeの機能"
          {...getOverrideProps(overrides, "IoTube\u306E\u6A5F\u80FD")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="23px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="27.83522605895996px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="137px"
          left="calc(50% - 211px - 3.5px)"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="簡単設置-LED蛍光灯と入れ替えるだけ！"
          {...getOverrideProps(
            overrides,
            "\u7C21\u5358\u8A2D\u7F6E-LED\u86CD\u5149\u706F\u3068\u5165\u308C\u66FF\u3048\u308B\u3060\u3051\uFF01"
          )}
        ></Text>
        <View
          width="860px"
          height="376.43px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="190px"
          left="116px"
          right="59px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 413")}
        >
          <View
            padding="0px 0px 0px 0px"
            width="837px"
            height="376.43px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="11px"
            {...getOverrideProps(overrides, "main")}
          >
            <View
              padding="0px 0px 0px 0px"
              width="143.19px"
              height="143.19px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="59.05px"
              left="0px"
              {...getOverrideProps(overrides, "Group 25")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="143.19px"
                height="143.19px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Group 78")}
              >
                <Icon
                  width="143.19px"
                  height="143.19px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 143.19049072265625,
                    height: 143.19140625,
                  }}
                  paths={[
                    {
                      d: "M143.19 71.5952C143.19 111.136 111.136 143.19 71.5952 143.19C32.0543 143.19 0 111.136 0 71.5952C0 32.0543 32.0543 0 71.5952 0C111.136 0 143.19 32.0543 143.19 71.5952Z",
                      fill: "rgba(255,255,255,1)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="calc(50% - 71.6px - 0px)"
                  {...getOverrideProps(overrides, "Ellipse 173861")}
                ></Icon>
                <Text
                  fontFamily="Inter"
                  fontSize="22px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.625px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="125.86px"
                  height="49.25px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="46.95px"
                  left="calc(50% - 62.93px - -4.34px)"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="魚眼レンズ搭載カメラ"
                  {...getOverrideProps(
                    overrides,
                    "\u9B5A\u773C\u30EC\u30F3\u30BA\u642D\u8F09\u30AB\u30E1\u30E9"
                  )}
                ></Text>
              </View>
            </View>
            <View
              padding="0px 0px 0px 0px"
              width="143.19px"
              height="143.19px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="59.05px"
              left="167.55px"
              {...getOverrideProps(overrides, "Group 26")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="143.19px"
                height="143.19px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Group 79")}
              >
                <Icon
                  width="143.19px"
                  height="143.19px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 143.1904754638672,
                    height: 143.19140625,
                  }}
                  paths={[
                    {
                      d: "M143.19 71.5952C143.19 111.136 111.136 143.19 71.5952 143.19C32.0543 143.19 0 111.136 0 71.5952C0 32.0543 32.0543 0 71.5952 0C111.136 0 143.19 32.0543 143.19 71.5952Z",
                      fill: "rgba(255,255,255,1)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="calc(50% - 71.6px - 0px)"
                  {...getOverrideProps(overrides, "Ellipse 173864")}
                ></Icon>
                <Text
                  fontFamily="Inter"
                  fontSize="22px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.625px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="128.6px"
                  height="51.07px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="51.07px"
                  left="calc(50% - 64.3px - 1.82px)"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="エッジAI&#xA;搭載"
                  {...getOverrideProps(
                    overrides,
                    "\u30A8\u30C3\u30B8AI \u642D\u8F09"
                  )}
                ></Text>
              </View>
            </View>
            <View
              padding="0px 0px 0px 0px"
              width="143.19px"
              height="143.19px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="59.05px"
              left="335.1px"
              {...getOverrideProps(overrides, "Group 27")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="143.19px"
                height="143.19px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Group 80")}
              >
                <Icon
                  width="143.19px"
                  height="143.19px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 143.1904754638672,
                    height: 143.19140625,
                  }}
                  paths={[
                    {
                      d: "M143.19 71.5952C143.19 111.136 111.136 143.19 71.5952 143.19C32.0543 143.19 0 111.136 0 71.5952C0 32.0543 32.0543 0 71.5952 0C111.136 0 143.19 32.0543 143.19 71.5952Z",
                      fill: "rgba(255,255,255,1)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="calc(50% - 71.6px - 0px)"
                  {...getOverrideProps(overrides, "Ellipse 173867")}
                ></Icon>
                <Text
                  fontFamily="Inter"
                  fontSize="22px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.625px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="98px"
                  height="46px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="62.95px"
                  left="calc(50% - 49px - -6.31px)"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="SIM搭載"
                  {...getOverrideProps(overrides, "SIM\u642D\u8F09")}
                ></Text>
              </View>
            </View>
            <View
              padding="0px 0px 0px 0px"
              width="145.81px"
              height="143.19px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="222.17px"
              left="267.19px"
              {...getOverrideProps(overrides, "Group 33")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="145.81px"
                height="143.19px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Group 778521")}
              >
                <Icon
                  width="143.19px"
                  height="143.19px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 143.1904754638672,
                    height: 143.19140625,
                  }}
                  paths={[
                    {
                      d: "M143.19 71.5952C143.19 111.136 111.136 143.19 71.5952 143.19C32.0543 143.19 0 111.136 0 71.5952C0 32.0543 32.0543 0 71.5952 0C111.136 0 143.19 32.0543 143.19 71.5952Z",
                      fill: "rgba(255,255,255,1)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="calc(50% - 71.6px - 1.31px)"
                  {...getOverrideProps(overrides, "Ellipse 178522")}
                ></Icon>
                <Text
                  fontFamily="Inter"
                  fontSize="22px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.625px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="143px"
                  height="55px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="49.83px"
                  left="calc(50% - 71.5px - -1.4px)"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="双方向通話&#xA;対応"
                  {...getOverrideProps(
                    overrides,
                    "\u53CC\u65B9\u5411\u901A\u8A71\u5BFE\u5FDC"
                  )}
                ></Text>
              </View>
            </View>
            <Image
              width="345.43px"
              height="376.43px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 172.71px - -245.79px)"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://theasight.net/website_image/Section4-img2.png"
              {...getOverrideProps(overrides, "Section4-img2 1")}
            ></Image>
            <View
              padding="0px 0px 0px 0px"
              width="152.79px"
              height="143.19px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="222.17px"
              left="71.6px"
              {...getOverrideProps(overrides, "Group 32")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="152.79px"
                height="143.19px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Group 778516")}
              >
                <Icon
                  width="143.19px"
                  height="143.19px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 143.1904754638672,
                    height: 143.19140625,
                  }}
                  paths={[
                    {
                      d: "M143.19 71.5952C143.19 111.136 111.136 143.19 71.5952 143.19C32.0543 143.19 0 111.136 0 71.5952C0 32.0543 32.0543 0 71.5952 0C111.136 0 143.19 32.0543 143.19 71.5952Z",
                      fill: "rgba(255,255,255,1)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="calc(50% - 71.6px - 0.39px)"
                  {...getOverrideProps(overrides, "Ellipse 178513")}
                ></Icon>
                <Text
                  fontFamily="Inter"
                  fontSize="22px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.625px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="152.79px"
                  height="47.24px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="52.83px"
                  left="calc(50% - 76.39px - 0px)"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="各種センサー&#xA;搭載"
                  {...getOverrideProps(
                    overrides,
                    "\u5404\u7A2E\u30BB\u30F3\u30B5\u30FC \u642D\u8F09"
                  )}
                ></Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <Flex
        /*padding="0px 0px 0px 0px"
        width="unset"
        height="154px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"*/
        position="absolute"
        bottom="-1px"
        left="0"
        width="0"
        height="0"
        {...getOverrideProps(overrides, "Group 96")}
        {...boderStyleBottom}
      >
        <Icon
          width="1035px"
          height="154px"
          viewBox={{ minX: 0, minY: 0, width: 1035, height: 154 }}
          paths={[
            {
              d: "M0 96.2968L1035 0L1035 154L0 154L0 96.2968Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ]}
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Rectangle 332")}
        ></Icon>
      </Flex>
    </Flex>
  );
}
